import { Document, PDFViewer, Page, View, Text } from "@react-pdf/renderer";
import React from "react";
import { TitleLeft, TitleRight } from "../Common/Components/title";
import { monthArr, toMonthAndFullYear, toVNDate } from "../Common/utils";
import PdfTable from "../Common/Components/pdfTable";
import { styles } from "../Common/style";
import { Footer, RenderSigns } from "../Common/Components/footer";
import { ReportName } from "../Common/Components/reportName";

export default function BienBanLayMau(props) {
  //Ngang
  const { currentDate, code, data, dicReports } = props;
  const info = data[0] || {};

  data &&
    data.forEach((element) => {
      element.soBienBan = dicReports[element.sampleId];
    });

  return (
    <PDFViewer style={{ width: "100%", height: "calc(100% - 40px)" }}>
      <Document>
        <Page size="A4" style={[styles.page]} orientation={"landscape"}>
          <View style={styles.section}>
            <View style={styles.row}>
              <View style={[styles.col_30, styles.textCenter]}>
                <TitleLeft />
              </View>
              <View style={styles.col_20}></View>
              <View style={[styles.col_50, styles.textCenter]}>
                <TitleRight />
                <Text
                  style={[
                    styles.italic,
                    { margin: "5px", marginRight: "50px", textAlign: "right" },
                  ]}
                >
                  {info.province || "........"}, ngày {currentDate.getDate()}{" "}
                  tháng {monthArr[currentDate.getMonth()]} năm{" "}
                  {currentDate.getFullYear()}
                </Text>
              </View>
            </View>
            <ReportName {...props}>
              <Text style={[styles.textCenter, styles.fS10]}>
                Số: {code} / {currentDate.getFullYear()}
              </Text>
            </ReportName>
            <Information1 {...props} />
            <View style={{ marginBottom: "5px" }}></View>
            <PdfTable {...props} />
            <View style={{ marginBottom: "5px" }}></View>
            <Information2 {...props} />
          </View>
          {/*  */}
          <Footer {...props} />
        </Page>
      </Document>
    </PDFViewer>
  );
}

function Information1(props) {
  const { data } = props;
  const info = data[0] || {};
  const samplers = [
    ...new Set(
      data
        .flatMap((x) => x.samplerInformation)
        .filter((x) => x?.samplerName)
        .map(
          (x) =>
            "Ông/bà: " +
            x.samplerName +
            ", chức vụ: " +
            (x?.samplerPosition ?? ".....")
        )
    ),
  ];
  const addresses = [
    info.pickingAddress,
    info.commune,
    info.ward,
    info.province,
  ]
    .map((x) => (!!x ? x : ""))
    .join(" - ");

  return (
    <View>
      <Text>
        Giấy giới thiệu Số: {info?.number || ""} Của Trung tâm Kiểm nghiệm
        thuốc, mỹ phẩm, thực phẩm tỉnh Yên Bái, cấp {toVNDate(info?.issuedDate)}
      </Text>

      <Text>Họ tên, chức vụ, cơ quan của những người tham gia lấy mẫu:</Text>
      <View style={{ margin: "4px 0 4px 0" }}>
        {samplers.map((sampler, index) => {
          return (
            <Text>
              {index + 1}. {sampler}. Trung tâm kiểm nghiệm thuốc, mỹ phẩm, thực
              phẩm Yên Bái {index === 0 ? "Trưởng đoàn" : "Thành viên"}
            </Text>
          );
        })}
      </View>

      <Text>
        Phương pháp lấy mẫu: Theo Phụ lục I Hướng dẫn việc lấy mẫu thuốc, nguyên
        liệu làm thuốc để xác định chất lượng
        <Text style={styles.italic}>
          (Ban hành Kèm theo Thông tư số 11/2018/TT-BYT ngày 04/5/2018 của Bộ
          trưởng Bộ Y tế quy định về chất lượng thuốc, nguyên liệu làm thuốc);
          Thông tư số 38/2021/TT-BYT ngày 31/12/2021 Quy định về chất lượng dược
          liệu, vị thuốc cổ truyền, thuốc cổ truyền
        </Text>
        ; Thông tư 06/2011/TT-BYT ngày 25/01/2011 của Bộ Y tế quy định về quản
        lý mỹ phẩm.
      </Text>

      <Text>Tên cơ sở được lấy mẫu: {`${info.locationName || ""}`}</Text>
      <Text>
        Phân loại cơ sở được lấy mẫu: {`${info.pickingSellType || ""}`}
      </Text>

      <Text>
        Người chịu trách nhiệm chuyên môn/chủ cơ sở:{" "}
        {`${info.representativePerson || ""}`}
      </Text>

      <Text>Địa chỉ: {`${addresses}`}</Text>
      <Text>Điện thoại: {`${info.pickingPhone || ""}`}</Text>
    </View>
  );
}

function Information2(props) {
  const { data, currentSubStanceType, currentDate } = props;
  const info = data[0] || {};
  let signs = [
    "Người lấy mẫu",
    "Đại diện cơ sở được lấy mẫu",
    "Người chứng kiến(Khi cần thiết)",
  ];
  // if (currentSubStanceType === REPORT_OBJECT.Cosmetic.value) {
  //   signs = [
  //     "Người lập",
  //     "Người lấy mẫu",
  //     "Người chứng kiến",
  //     "Đại diện cơ sở được lấy mẫu",
  //   ];
  // }
  const toVnNumberWithSuffix = (input, suffix) => {
    return input
      ? input.toString().replace(".", ",") + ` ${suffix}`
      : "...........";
  };

  return (
    <>
      {/* footer info */}
      <View>
        <Text>
          Điều kiện bảo quản khi lấy mẫu: Nhiệt độ:{" "}
          {toVnNumberWithSuffix(info.temperature, "°C")}. Độ ẩm:{" "}
          {toVnNumberWithSuffix(info.humidity, "%")}
        </Text>

        <Text>
          Biên bản được lập vào hồi {currentDate.getHours()} giờ{" "}
          {currentDate.getMinutes()} phút cùng ngày, được lập thành{" "}
          {info.si3 || "......"} bản có nội dung, giá trị như nhau, đã được các
          bên thông qua: {info.si4 || "......"} bản lưu tại cơ sở được lấy mẫu;{" "}
          {info.si5 || "......"} bản lưu tại cơ quan Kiểm nghiệm; ...... bản lưu
          tại ..................................................................
        </Text>
      </View>
      {/* footer signs */}
      <RenderSigns titles={signs} />
    </>
  );
}
